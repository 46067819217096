<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center v-if="pageData">
      <v-flex xs12 text-center py-4 pt-6>
        <span style="font-size: 20px; font-weight: 500"><b> FEEDBACK </b></span>
      </v-flex>
      <v-flex
        xs12
        sm11
        md6
        lg4
        xl4
        px-4
        py-4
        xl8
        align-self-center
        v-if="!flag"
      >
        <v-card
          tile
          flat
          class="align-center"
          outlined
          :min-height="$vuetify.breakpoint.name == 'xs' ? '400px' : '350px'"
        >
          <v-layout wrap pa-12 v-if="pageData.name">
            <v-flex xs12 py-2 align-self-center>
              <span style="font-family: poppinsregular" class="editbtn"
                >From</span
              ><br />
              <span
                style="color: green; font-family: poppinsregular"
                class="gmails"
                >{{ pageData.name }}</span
              ><br />
              <span
                style="color: green; font-family: poppinsregular"
                class="gmails"
                >{{ pageData.email }}</span
              >
            </v-flex>
            <v-flex xs12 py-2 pt-4 align-self-center>
              <span style="font-family: poppinssemibold; font-size: 20px"
                >Product Details</span
              >
            </v-flex>
            <v-flex xs12 align-self-center>
              <span style="font-family: poppinssemibold">{{
                pageData.product.name
              }}</span>
              <br>
              <span style="font-family: poppinssemibold">{{
                pageData.unitname
              }}</span>
            </v-flex>
            <v-flex xs12 align-self-center style="font-family: poppinsregular">
              <span>Quantity: </span>
              <span style="font-family: poppinssemibold"
                >{{ pageData.quantity }} * {{ pageData.sizename }}</span
              >
              <br />
              <span>Grand Total: </span>
              <span style="font-family: poppinssemibold">
                {{ pageData.total }} INR</span
              >
            </v-flex>
            <!-- <v-flex xs12 align-self-center>
              <frontImage
                @stepper="winStepper"
                :height="'720'"
                :width="'720'"
                :heading="'Upload Your Image'"
                :componentType="'frontrImage'"
              />
            </v-flex> -->
            <v-flex xs12 py-2 pt-8 align-self-center>
              <span style="font-family: poppinsregular">Rate Us: </span>
              <v-rating
                background-color="warning lighten-1"
                color="warning"
                half-icon="$mdiStarHalfFull"
                hover
                length="5"
                size="40"
                :value="rating"
                v-model="rating"
                :rules="reRules"
              ></v-rating>
            </v-flex>
            <v-flex xs12 align-self-center>
              <v-textarea
                dense
                outlined
                class="addressedit"
                label="Comment"
                color="#68D389"
                placeholder="enter here"
                v-model="comment"
                :rules="reRules"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 align-self-center>
              <v-btn
                width="100%"
                :ripple="false"
                color="#FFD23E"
                @click="validateInput()"
              >
                <span class="btnProg"
                  >SUBMIT <v-icon>mdi-arrow-right</v-icon></span
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm11 md6 lg4 xl4 px-4 py-4 xl8 v-if="flag">
        <v-card
          tile
          flat
          outlined
          :max-height="$vuetify.breakpoint.name == 'xs' ? '400px' : '350px'"
        >
          <v-layout wrap justify-center pa-12>
            <v-flex xs12 align-self-center text-center>
              <!-- <span class="gmails"
                >You have already submitted your feedback. Thank you.</span
              > -->
              <span class="gmails">{{ msg }}</span>
              <br />
              <br />
              <br />
              <v-layout wrap justify-center>
                <v-flex xs12 sm6 md5 lg4 align-self-center text-center>
                  <v-btn
                    small
                    width="100%"
                    to="/"
                    :ripple="false"
                    color="#FFD23E"
                  >
                    <span class="btnProg"
                      ><v-icon>mdi-arrow-left</v-icon> Home</span
                    >
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      menu: false,
      pageData: [],
      feedphoto: "",
      frontImageFile: null,
      singleImage: null,
      formDataFront: new FormData(),
      image: null,
      imageArray: [],
      comment: "",
      flag: false,
      rating: null,
      reRules: [
        (v) => !!v || "Field required",
      ],
    };
  },
  // created: {
  //   guestID() {
  //     return this.$route.query.guestid;
  //   },
  //   ticketID() {
  //     return this.$route.query.ticketid;
  //   },
  // },
  components: {
    // frontImage,
  },
  beforeMount() {
    if (this.$route.query.id) this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "frontrImage") {
        this.frontImageFile = window_data.selectedFilesfr;
        this.feedphoto = window_data.singleImage;
      }
      if (window_data.getData) this.getData();
    },
    validateInput() {
      if (!this.comment) {
        this.msg = "Please provide comment";
        this.showSnackBar = true;
        // return;
      }
      else if (!this.rating) {
        this.msg = "Please provide rating";
        this.showSnackBar = true;
        // return;
      } else this.sendData();
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productfeedback/checkduplicate",
        params: {
          purchase: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.pageData = response.data.data;
          } else {
            this.flag = true;
            // alert("You have already submitted your feedback. Thank you.");
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/productfeedback/Add",
        data: {
          purchase: this.$route.query.id,
          rating: this.rating,
          comment: this.comment,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.pageData = response.data.data;
            this.flag = true;
            this.comment = "";
            this.msg = response.data.msg;
          } else {
            // alert("You have already submitted your feedback. Thank you.");
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push("/");
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
</style>